import { CloseOutlined } from "@ant-design/icons";
import { Modal, Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";

const { Title, Text } = Typography;

interface props {
  data: any[];
  setData: (data: any[]) => void;
}

const ContactErrorModal = forwardRef(({ data, setData }: props, ref: any) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const columns: ColumnsType<any> = [
    {
      title: "Row Number",
      dataIndex: "row",
      key: "row",
      fixed: "left",
      width: 150,
    },
    {
      title: "Error",
      dataIndex: "errors",
      key: "errors",
      render: (text) => (
        <span>
          {text?.map((i: string) => (
            <Text>{i}</Text>
          ))}
        </span>
      ),
    },
  ];

  useImperativeHandle(ref, () => ({
    closeModal: () => {
      setShow(false);
      setData([]);
    },
    openModal: () => {
      setShow(true);
    },
  }));

  return (
    <Modal
      centered
      destroyOnClose
      open={show}
      okButtonProps={{ htmlType: "submit" }}
      closeIcon={
        <CloseOutlined
          onClick={() => {
            setShow(false);
            setData([]);
          }}
        />
      }
      title={
        <Title className="font-size-24 font-weight-500">
          {t("heading.error")}
        </Title>
      }
      className="error-contact-modal"
      footer={false}
    >
      <Text className="font-size-16 font-weight-700">
        {t("heading.SavedExcept")}
      </Text>
      <Table
        pagination={false}
        size="small"
        style={{ width: "100%", marginTop: "1rem" }}
        columns={columns}
        dataSource={data ?? []}
      />
    </Modal>
  );
});

export default ContactErrorModal;
