import { CloseCircleFilled } from "@ant-design/icons";
import {
  Col,
  DatePicker,
  Flex,
  Form,
  Input,
  Menu,
  MenuProps,
  Row,
  Spin,
  Tag,
  Tooltip,
  Typography,
  notification,
} from "antd";
import en from "antd/es/date-picker/locale/en_US";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { maxSize } from "src/constants/common";
import { returnElipsesText } from "src/constants/functions";
import { getCurrentDidId } from "src/store/selectors/features/currentDid";
import { getTemplateData } from "src/store/selectors/features/template";
import { uploadFilesLoading } from "src/store/selectors/features/uploadFiles";
import RequestAppAction from "src/store/slices/appActions";
import Colors from "src/themes/colors";
import Button from "../button";
import { TextifyModal } from "../modal";
import AddVariableBtn from "../modal-button-container/add-variable/AddVariableBtn";
import ModalButtonContainer from "../modal-button-container";
import AttachmentBtn from "../modal-button-container/AttachmentBtn";
import TemplateBtn from "../modal-button-container/TemplateBtn";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import { firstName } from "src/constants/add-variable";

dayjs.extend(buddhistEra);

const buddhistLocale = {
  ...en,
  lang: {
    ...en.lang,
    fieldDateFormat: "YYYY-MM-DD",
    fieldDateTimeFormat: "YYYY-MM-DD h:mm",
    yearFormat: "YYYY",
    cellYearFormat: "YYYY",
  },
} as typeof en;

interface props {
  modalRef: any;
  phoneNumbers: string[];
  setSelectedPhoneNumber: any;
  images: string[] | [];
  content: string;
  bulkContacts: any[];
}

export const AddBulkScheduleMessageModal = forwardRef(
  (
    {
      modalRef,
      phoneNumbers,
      setSelectedPhoneNumber,
      images,
      content,
      bulkContacts,
    }: props,
    ref
  ) => {
    const dispatch = useDispatch();
    const { Text } = Typography;
    const { t } = useTranslation();
    const { width } = useWindowDimensions();
    const textareaRef: any = useRef<any>(null);
    const uploadFileRef: any = useRef<HTMLInputElement | null>(null);
    const templates: any = useSelector(getTemplateData);
    const attachmentLoading = useSelector(uploadFilesLoading);
    const [units, setUnits] = useState<number>(0);
    const [form] = useForm();
    const [characters, setCharacters] = useState<number>(0);
    const [totalCharacters, setTotalCharacters] = useState<String>("");
    const [message, setMessage] = useState<string>("");
    const [variableList, setVariableList] = useState<any>([]);
    const [attachments, setAttachments] = useState<any>([]);
    const did = useSelector(getCurrentDidId);
    const [selectedPhoneNumbers, setSelectedPhoneNumbers] = useState<
      string[] | []
    >([]);

    useEffect(() => {
      if (images?.length > 0) {
        setAttachments(images ?? []);
      }

      if (content) {
        form.setFieldValue("content", content);
        const charactersLength = content.length;
        const totalUnits = Math.floor(charactersLength / 160);
        const chr = charactersLength - (totalUnits || 0) * 160;

        if (charactersLength === 0 && attachments.length === 0) {
          setUnits(0);
        } else if (charactersLength > 0) {
          let val = totalUnits + 1 + attachments?.length * 3;
          setUnits(val);
        } else {
          let val = attachments?.length * 3;
          setUnits(val);
        }

        setCharacters(chr);
      }
    }, [images, content]);

    useImperativeHandle(ref, () => ({
      closeModal: () => {
        form.resetFields();
        setCharacters(0);
        setUnits(0);
        modalRef.current?.closeModal();
        setSelectedPhoneNumber([]);
      },
    }));

    useEffect(() => {
      if (phoneNumbers) {
        setSelectedPhoneNumbers(phoneNumbers);
      }
    }, [phoneNumbers]);

    useEffect(() => {
      const variables = [];
      variables.push(firstName);
      setVariableList(variables);
    }, []);

    useEffect(() => {
      const charactersLength = totalCharacters.length;
      const totalUnits = Math.floor(charactersLength / 160);
      const chr = charactersLength - (totalUnits || 0) * 160;

      if (charactersLength === 0 && attachments.length === 0) {
        setUnits(0);
      } else if (charactersLength > 0) {
        let val = totalUnits + 1 + attachments?.length * 3;
        setUnits(val);
      } else {
        let val = attachments?.length * 3;
        setUnits(val);
      }

      setCharacters(chr);
    }, [totalCharacters, attachments]);

    const onTextChange = (value: string) => {
      setMessage(value);
      setTotalCharacters(value.trim());
    }

    const handleTextChange = async (e: any) => {
      onTextChange(e.target.value);
    };

    const handleCancel = () => {
      form.resetFields();
      setCharacters(0);
      setUnits(0);
      setTotalCharacters("");
      setMessage("");
      setAttachments([]);
      modalRef.current?.closeModal();
      setSelectedPhoneNumber([]);
    };
    const onSave = async (values: any) => {
      await form
        .validateFields()
        .then(() => {
          const numFixArr = selectedPhoneNumbers?.map((i: string) =>
            i?.replace(/[\s-]/g, "")
          );

          const updatedArr = numFixArr?.map((i: string) =>
            i?.startsWith("+1")
              ? i
              : i?.startsWith("1") && i?.length === 11
              ? `+${i}`
              : `+1${i}`
          );

          const scheduledTime = new Date(values.scheduledTime);
          const data: {
            content: string;
            scheduledTime: string;
            fileLocations?: any[];
            phoneNumber: string[];
            variableList?: any[];
          } = {
            content: values.content,
            scheduledTime: scheduledTime.toISOString(),
            phoneNumber: updatedArr,
            variableList: variableList,
          };
          if (attachments.length > 0) {
            data["fileLocations"] = attachments;
          }
          dispatch(
            RequestAppAction.postBulkScheduleMessage({
              data: data,
              id: did,
              cbSuccess: () => {
                notification.success({
                  message: t("notification.saveScheduledSuccess"),
                });
                handleCancel();
                setAttachments([]);
              },
              cbFailure: (e: string) => {
                notification.success({
                  message: e,
                });
              },
            })
          );
        })
        .catch(() => {
          notification.error({ message: t("notification.saveScheduledFail") });
          return;
        });
    };

    const range = (start: any, end: any) =>
      Array.from({ length: end - start }, (_, i) => start + i);

    const disabledDateTime = (current: any) => {
      const currentDayjs = dayjs();

      const isToday = dayjs().date();

      return {
        disabledHours: () => (isToday ? range(0, currentDayjs?.hour()) : []),
        disabledMinutes: () =>
          isToday && currentDayjs?.hour() === current?.hour()
            ? range(0, currentDayjs.minute() + 1)
            : [],
      };
    };

    const handleMenuItemClick = (item: any) => {
      form.setFieldsValue({
        content: item.content,
      });
    };

    const menuItems: MenuProps["items"] = templates?.items?.map(
      (template: { content: any; title: string }, index: any) => ({
        key: String(index),
        label: template.title,
        content: template?.content,
      })
    );

    const menu = () => (
      <Menu>
        {menuItems?.map((menuItem: any, index: number) => (
          <Menu.Item onClick={() => handleMenuItemClick(menuItem)} key={index}>
            {menuItem.label}
          </Menu.Item>
        ))}
      </Menu>
    );

    const upload = (e: any) => {
      const formData = new FormData();
      const files = e.target.files;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file.size > maxSize) {
          // Handle error: File size exceeds 5MB
          notification.error({
            message: t("common.fileSizeError", { name: file.name }),
          });

          return;
        }
      }

      for (const file of files) {
        formData.append("files", file);
      }

      dispatch(
        RequestAppAction.uploadFiles({
          data: formData,
          cbSuccess: (res: any) => {
            uploadFileRef.current.value = "";
            setAttachments((pre: any) => [...pre, ...res?.data]);
          },
          cbFailure: (e: string) => {
            uploadFileRef.current.value = "";
            notification.error({ message: e });
          },
        })
      );
    };

    useEffect(() => {
      if (selectedPhoneNumbers.length < 1) {
        form.resetFields();
        setCharacters(0);
        setUnits(0);
        modalRef.current?.closeModal();
        setSelectedPhoneNumber([]);
      }
    }, [selectedPhoneNumbers]);

    return (
      <div style={{ zIndex: 9999 }}>
        <TextifyModal ref={modalRef}>
          <input
            ref={uploadFileRef}
            onChange={(e) => {
              upload(e);
            }}
            style={{ display: "none" }}
            type="file"
            multiple
          />
          <Spin spinning={attachmentLoading}>
            <Flex vertical>
              <Spin spinning={false}>
                <Form
                  onFinish={(values) => onSave(values)}
                  form={form}
                  name="scheduleForm"
                >
                  <div className="p-5 pb-0">
                    <div className="d-flex justify-content-between align-items-center pt-5 ">
                      <Form.Item
                        name="scheduledTime"
                        rules={[
                          { required: true, message: "Please Select Date" },
                        ]}
                      >
                        <DatePicker
                          showTime
                          disabledDate={(curr) =>
                            curr.isBefore(dayjs().subtract(1, "day"))
                          }
                          disabledTime={disabledDateTime}
                          locale={buddhistLocale}
                          use12Hours
                          format="YYYY-MM-DD h:mm A"
                          placeholder="Select Date and Time"
                        />
                      </Form.Item>
                    </div>
                    <div className="pb-5 row">
                      {bulkContacts?.map((i) => (
                        <>
                          <Tag
                            closeIcon
                            onClose={() => {
                              setSelectedPhoneNumbers(
                                selectedPhoneNumbers.filter(
                                  (item) => item !== i?.phoneNumber
                                )
                              );
                            }}
                            style={{ borderRadius: 3 }}
                          >
                            {i?.name || i?.phoneNumber}
                          </Tag>
                        </>
                      ))}
                    </div>
                    <div className="d-flex justify-content-between align-items-center pt-5">
                      <Text className="font-size-16">
                        {t("sideBar.message")}
                      </Text>
                      <Text className="">
                        <div>
                          {t("sideBar.chars")} {characters}/160
                          <span className="pl-5">
                            {t("sideBar.msgUnits")} {units}/10
                          </span>
                        </div>
                      </Text>
                    </div>
                    <div
                      className="align-items-end pb-5"
                      style={{ position: "relative" }}
                    >
                      <Form.Item
                        name="content"
                        rules={[
                          // {
                          //   required: true,
                          //   message: "Please add some content",
                          // },
                          {
                            validator: (_, value) => {
                              if (!value && (!attachments || attachments.length === 0)) {
                                return Promise.reject(
                                  "Please add some content"
                                );
                              }
                              if (value && value.length > 1600) {
                                return Promise.reject(
                                  "Content length cannot exceed 1600 characters"
                                );
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Input.TextArea
                          ref={textareaRef}
                          style={{ resize: "none" }}
                          rows={8}
                          placeholder="Type your text here..."
                          onChange={handleTextChange}
                          autoFocus
                        />
                      </Form.Item>
                      <ModalButtonContainer>
                        {width > 576 && (
                          <AddVariableBtn
                            disabled={!variableList || variableList.length === 0}
                            variableList={variableList}
                            form={form}
                            textareaRef={textareaRef}
                            message={message}
                            onTextChange={onTextChange}
                            style={{ borderRadius: 50, marginLeft: 10 }}
                          />
                        )}
                        <AttachmentBtn
                          uploadFileRef={uploadFileRef}
                          style={{ borderRadius: 50, marginLeft: 10 }}
                        />
                        <TemplateBtn
                          templateMenu={menu}
                          style={{ borderRadius: 50, marginLeft: 10 }}
                        />
                      </ModalButtonContainer>
                    </div>
                  </div>
                  <Row>
                    <Col
                      style={{
                        display: "flex",
                      }}
                      span={24}
                    >
                      {attachments.map((i: string, ind: number) => (
                        <div
                          style={{
                            position: "relative",
                            marginRight: "0.5rem",
                          }}
                        >
                          <Tag
                            onClick={() => {
                              window.open(i, "_blank");
                            }}
                            style={{
                              background: Colors.white,
                              borderRadius: 4,
                            }}
                          >
                            <Text
                              key={ind}
                              className="font-size-14"
                              style={{
                                display: "flex",
                                cursor: "pointer  ",
                              }}
                            >
                              {returnElipsesText(i)}
                            </Text>
                          </Tag>
                          <CloseCircleFilled
                            onClick={() => {
                              const arr = attachments;
                              setAttachments(
                                arr.filter((item: any) => item !== i)
                              );
                            }}
                            style={{
                              color: Colors.red,
                              position: "absolute",
                              top: -10,
                              right: 0,
                              zIndex: 2,
                            }}
                          />
                        </div>
                      ))}
                    </Col>
                  </Row>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      padding: "10px",
                    }}
                  >
                    <div style={{ marginLeft: "auto", marginRight: "10px" }}>
                      <Button
                        onBtnClick={handleCancel}
                        title={"Cancel"}
                        btnClass="white_btn"
                      />
                    </div>
                    <div>
                      {units > 10 ? (
                        <Tooltip
                          trigger={"hover"}
                          color="red"
                          title={t("message.unitError")}
                        >
                          <Button
                            title={"Save"}
                            disabled={true}
                            buttonType="button"
                          />
                        </Tooltip>
                      ) : (
                        <Button title={"Save"} buttonType="submit" />
                      )}
                    </div>
                  </div>
                </Form>
              </Spin>
            </Flex>
          </Spin>
        </TextifyModal>
      </div>
    );
  }
);
